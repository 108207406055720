<!--
 * @Author: DQL
 * @Date: 2020-03-16 15:51:47
 * @LastEditors: zxf
 * @LastEditTime: 2023-09-20 17:31:42
 * @Description: 切换身份页面
 -->
<template>
  <div
    class="c-switch-subject"
  >
    <div
      v-loading="logning"
      class="c-switch-subject__main"
    >
      <h2
        class="c-switch-subject__title"
      >
        选择进入系统的身份
      </h2>
      <el-table
        style="width: 100%;"
        max-height="500px"
        border
        highlight-current-row
        :data="capacities"
        @row-click="handleRowClick"
      >
        <el-table-column
          class-name="c-table-column--selection"
          width="30"
        >
          <template
            slot-scope="scope"
          >
            <el-radio
              v-model="selectedCapacityCode"
              :label="scope.row.capacityCode"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="capacityName"
          label="身份名称"
          width="200"
          header-align="center"
        />
        <el-table-column
          prop="deptName"
          label="机构部门"
          header-align="center"
        />
        <!-- <el-table-column
          prop="isPrimary"
          label="是否主身份"
          width="100"
          header-align="center"
        >
          <template
            slot-scope="scope"
            class="radio-cell"
          >
            {{ scope.row.isPrimary | sfFilter }}
          </template>
        </el-table-column> -->
      </el-table>
      <div
        class="u-mg-t-20 u-text-center"
      >
        <el-button
          @click="handleClickResetLogin"
        >
          重新登录
        </el-button>
        <el-button
          type="primary"
          :disabled="!selectedCapacityCode || selectedCapacityCode===currentCapacityCode"
          @click="handleClickGoInSystem"
        >
          进入系统
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { changeSubject } from '@/features/feature.common.service';
import _ from 'lodash';
import config from '@/config';

export default {
  name: 'SwitchSubject',
  data () {
    return {
      logning: false, // 登录ing
      capacities: [], // 身份列表
      currentCapacityCode: null, // 当前登录身份
      selectedCapacityCode: null // 选中身份
    };
  },
  computed: {
    currentUser () {
      return this.$HMStoreService.getUserinfo();
    }
  },
  methods: {
    /**
     * @description: 表格选中行事件（单选）
     * @param {Object} row
     * @param {Object} column
     * @param {any} event
     * @return {*}
     */
    handleRowClick (row, column, event) {
      this.selectedCapacityCode = row.capacityCode;
    },
    /**
     * @description: 选择身份进入系统
     * @param {*}
     * @return {*}
     */
    handleClickGoInSystem () {
      this.logning = true;
      changeSubject(this.selectedCapacityCode)
        .then(rsp => {
          /* 更新token Start */
          const token = rsp.headers[config.serverHeaderTokenName];
          this.$HMCookies.set(config.localTokenName, token);
          /* 更新token End */

          this.$router.push('/');
        });
    },
    /**
     * @description: 重新登录
     * @param {*}
     * @return {*}
     */
    handleClickResetLogin () {
      this.logning = false;
      this.$HMCookies.unset(config.localTokenName);
      this.$router.push({ path: '/login' });
    },
    /**
     * @description: 页面初始化
     * @param {*}
     * @return {*}
     */
    init () {
      // 获取身份列表
      this.capacities = this.currentUser.capacities;
      this.currentCapacityCode = this.currentUser.capacity ? this.currentUser.capacity.capacityCode : null;
      this.selectedCapacityCode = _.clone(this.currentCapacityCode);
    }
  },
  mounted () {
    this.init();
    document.getElementById('preloading').style.display = 'none';
  }
};

</script>

<style scoped lang="scss">

@import "./switch-subject.scss";

</style>
